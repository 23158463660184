import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import AuthorBlogs from './AuthorBlogs'
import { DISPLAY_TAG_IDENTIFIER } from './constants'
import { POOL } from '../internal-links'
import './BlogList.scss'

interface GatsbyTag {
  name: string
}

interface Props {
  title: string
  slug: string
  tags: GatsbyTag[]
  prefix: string
  layout?: string
  author: string
  html: string
  feature_image: string
  reading_time: number
  updated_at: string
}

const BlogList: React.FC<Props> = ({
  title,
  slug,
  tags,
  prefix,
  layout,
  html,
  author,
  feature_image,
  reading_time,
  updated_at,
}) => {
  const data = useStaticQuery(graphql`
    {
      Irshad: file(relativePath: { eq: "Authors/Irshad-ahmed-author.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 45, layout: CONSTRAINED)
        }
      }
      Kanchan: file(
        relativePath: { eq: "Authors/kanchan-poker-author-desktop.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 45, layout: CONSTRAINED)
        }
      }
      Rohan: file(relativePath: { eq: "Authors/rohan-author.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 45, layout: CONSTRAINED)
        }
      }
      Shahla: file(relativePath: { eq: "Authors/shahla-author.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 45, layout: CONSTRAINED)
        }
      }
    }
  `)
  const prefixes = prefix.split('/')
  const prefixCat = prefixes[prefixes.length - 2]
  const prefixCatLast = prefixes[prefixes.length - 1]

  let authorName = `${author}`
  if (prefixCat === 'rummy' || prefixCatLast === 'earn-money') {
    authorName = 'Rohan Mathwan'
  } else if (prefixCat === 'poker') {
    authorName = 'Kanchan Sharma'
  } else if (prefixCat === 'carrom') {
    authorName = 'Irshad Ahmed'
  } else {
    authorName = 'Shahla Jabbeen'
  }

  let authorImage = data.Shahla.childImageSharp.gatsbyImageData

  if (authorName === 'Irshad Ahmed') {
    authorImage = data.Irshad.childImageSharp.gatsbyImageData
  } else if (authorName === 'Kanchan Sharma') {
    authorImage = data.Kanchan.childImageSharp.gatsbyImageData
  } else if (authorName === 'Rohan Mathwan') {
    authorImage = data.Rohan.childImageSharp.gatsbyImageData
  } else {
    authorImage = data.Shahla.childImageSharp.gatsbyImageData
  }

  const getFeaturedImage = (gameCat: string) => {
    switch (gameCat) {
      case 'poker':
        return (
          <StaticImage
            loading="eager"
            src="../images/featured-images/poker-blogs.jpg"
            alt="getmega"
            width={730}
            height={311}
          />
        )
      case 'rummy':
        return (
          <StaticImage
            loading="eager"
            src="../images/featured-images/rummy-blogs.jpg"
            alt="getmega"
            width={730}
            height={311}
          />
        )
      default:
        return (
          <StaticImage
            loading="eager"
            src="../images/featured-images/other-blogs.jpg"
            alt="getmega"
            width={730}
            height={311}
          />
        )
    }
  }

  const displayTags = tags
    .filter(t => t.name.startsWith(DISPLAY_TAG_IDENTIFIER))
    .map(t => `${t.name.replace(DISPLAY_TAG_IDENTIFIER, '')}`)

  return layout === 'blogAuthors' ? (
    <AuthorBlogs
      html={html}
      feature_image={feature_image}
      prefix={prefix}
      slug={slug}
      author={author}
    />
  ) : (
    <Link to={prefix === POOL ? `${prefix}${slug}/` : `${prefix}/${slug}/`}>
      <div className="Blog">
        <div className="blog-list-img">
          {feature_image ? (
            <img src={feature_image} alt={title} width="300" height="250" />
          ) : (
            getFeaturedImage(prefixCat)
          )}
        </div>
        <div className="blog-list-content">
          <h2 className="blog-title">{title}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
          <div className="extra-info-area">
            <div className="profile-image">
              <GatsbyImage
                image={authorImage}
                imgStyle={{ objectFit: 'cover', objectPosition: 'center top' }}
                alt={author}
                className="author-image"
              />
            </div>
            <div className="extra_info">
              <p>{authorName}</p>
              <p>
                {updated_at} | {reading_time} min read
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default BlogList
