import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import './BlogSearchInput.scss'

interface Props {
  onChange(s: string): void
}

const BlogSearchInput: React.FC<Props> = ({ onChange }) => {
  const data = useStaticQuery(graphql`
    {
      search: file(relativePath: { eq: "search-grey.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <div className="BlogSearchInput">
      <div className="search-icon">
        <GatsbyImage
          image={data.search.childImageSharp.gatsbyImageData}
          alt=""
          className="search-icon-image"
        />
      </div>
      <input
        type="text"
        onChange={e => {
          onChange(e.currentTarget.value)
        }}
        placeholder="Start typing to Search"
      />
    </div>
  )
}

export default BlogSearchInput
