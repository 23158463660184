import React, { useEffect, useState } from 'react'
import usePokerBanners, {
  PokerBanner,
} from '../../components/Games/Cards/Poker/usePokerBanners'
import getRandom from '../../lib/k-random-from-array'
import { getRandomItem, renderBottomBanners, renderSideBanners } from './helper'

interface Props {
  openPopup: () => void
  gameType: string
  position: 'side' | 'bottom'
}

const SideFooterBanners: React.FC<Props> = ({
  openPopup,
  gameType,
  position = 'side',
}) => {
  const [pokerImageBanners, setPokerImageBanners] = useState<PokerBanner[]>([])

  const pokerBanners = usePokerBanners()

  useEffect(() => {
    const pkbanner1 = getRandom(
      pokerBanners.filter(p => p.position === 1),
      2
    )
    const pkbanner2 = getRandom(
      pokerBanners.filter(p => p.position === 2),
      2
    )

    setPokerImageBanners([...pkbanner1, ...pkbanner2])
  }, [pokerBanners])

  const renderSidebarBanners = (game: string) => {
    switch (game) {
      case 'Poker':
        return renderSideBanners(pokerImageBanners, openPopup)
      default:
        return getRandomItem([
          renderSideBanners(pokerImageBanners, openPopup),
        ])
    }
  }

  const renderFooterBanners = (game: string) => {
    switch (game) {
      case 'Poker':
        return renderBottomBanners(pokerImageBanners, openPopup)
      default:
        return getRandomItem([
          renderBottomBanners(pokerImageBanners, openPopup),
        ])
    }
  }

  return position === 'side' ? (
    <>{renderSidebarBanners(gameType)}</>
  ) : (
    <>{renderFooterBanners(gameType)}</>
  )
}
export default SideFooterBanners
