import React, { useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Props {
  html: string
  feature_image: string
  prefix: string
  slug: string
  author: string
}

const AuthorBlogs: React.FC<Props> = ({
  html,
  feature_image,
  prefix,
  slug,
  author,
}) => {
  const data = useStaticQuery(graphql`
    {
      iconFb: file(relativePath: { eq: "fb-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 16, layout: FIXED)
        }
      }
      iconTwitter: file(relativePath: { eq: "twitter-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 16, layout: FIXED)
        }
      }
      iconInsta: file(relativePath: { eq: "insta-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 16, layout: FIXED)
        }
      }
      iconLocation: file(relativePath: { eq: "location-icon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 8, layout: FIXED)
        }
      }
    }
  `)

  const [loaded, setLoaded] = useState(false)

  const [authorBio, setAuthorBio] = useState<any | null>([])
  const [authorLocation, setAuthorLocation] = useState<any | null>([])
  const [authorFacebook, setAuthorFacebook] = useState<any | null>([])
  const [authorTwitter, setAuthorTwitter] = useState<any | null>([])
  const [authorInstagram, setAuthorInstagram] = useState<any | null>([])

  useEffect(() => {
    const tableContent = document.createElement('div')
    tableContent.innerHTML = `${html}`

    const table = tableContent?.querySelectorAll('table')[0]

    if (table) {
      // setting content
      const rows = table.getElementsByTagName('tr')
      const content = []
      for (let i = 1; i < rows.length; i += 1) {
        const tds = rows[i].getElementsByTagName('td')
        content.push({
          id: i,
          type: tds[0].textContent,
          value: tds[1].textContent,
        })
      }
      setAuthorBio(
        content.filter(item => item.type === 'About').map(item => item.value)[0]
      )
      setAuthorLocation(
        content
          .filter(item => item.type === 'Location')
          .map(item => item.value)[0]
      )
      setAuthorFacebook(
        content
          .filter(item => item.type === 'Facebook')
          .map(item => item.value)[0]
      )
      setAuthorTwitter(
        content
          .filter(item => item.type === 'Twitter')
          .map(item => item.value)[0]
      )
      setAuthorInstagram(
        content
          .filter(item => item.type === 'Instagram')
          .map(item => item.value)[0]
      )
    }

    setLoaded(true)
  }, [html, loaded])

  return loaded ? (
    <>
      <div className="authors-list-item">
        <div className="authors-list-top">
          <div className="authors-list-image">
            <Link to={`${prefix}/${slug}/`}>
              <img
                src={feature_image}
                alt="feature_image"
                width="164"
                height="164"
              />
            </Link>
          </div>
          <div className="authors-list-top-texts">
            <h3>
              <Link to={`${prefix}/${slug}/`}>{author}</Link>
            </h3>
            <div className="authors-social-icons">
              {authorFacebook && (
                <a href={authorFacebook} title="facebook">
                  <GatsbyImage
                    image={data.iconFb.childImageSharp.gatsbyImageData}
                    alt="icon"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </a>
              )}
              {authorInstagram && (
                <a href={authorInstagram} title="instagram">
                  <GatsbyImage
                    image={data.iconInsta.childImageSharp.gatsbyImageData}
                    alt="icon"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </a>
              )}
              {authorTwitter && (
                <a href={authorTwitter} title="twitter">
                  <GatsbyImage
                    image={data.iconTwitter.childImageSharp.gatsbyImageData}
                    alt="icon"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="authors-list-bottom">
          {authorBio && (
            <p>
              {authorBio.substring(0, 150)}...
              <Link to={`${prefix}/${slug}/`}>Read More</Link>
            </p>
          )}
          <div className="authors-location">
            <GatsbyImage
              image={data.iconLocation.childImageSharp.gatsbyImageData}
              alt="icon"
              imgStyle={{ objectFit: 'contain' }}
            />
            <span>{authorLocation}</span>
          </div>
        </div>
      </div>
    </>
  ) : null
}

export default AuthorBlogs
